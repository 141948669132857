.container {
  th[class*="ant-table-cell"] {
    padding: 8px 1px 8px 1px !important;
    height: 16px !important;
    line-height: 20px !important;
    text-align: center !important;
    font-stretch: expanded !important;
    background-color: #fff !important;
    border-radius: 0px !important;
  }
  td[class*="ant-table-cell"] {
    padding: 3px 1px 3px 1px !important;
    text-align: center;
    font-stretch: expanded !important;
    font-family: Arial, Helvetica, sans-serif;
    border: none;
  }
  thead[class*="antd-table-thead"] {
    padding: 0px;
    border-radius: 0px !important;
    border: none;
    border-color: #fff !important;
    text-align: center;
  }
  tr[class*="antd-table-thead"] {
    padding: 0px;
    border: none;
    border-color: #fff !important;
    text-align: center;
  }
  tr[class*="antd-table-row"] {
    padding: 0px !important;
    border: none !important;
    border-color: #fff !important;
    text-align: center !important;
  }
  tr[class*="antd-table-row"] {
    padding: 0px !important;
    border: none !important;
    border-color: #fff !important;
    text-align: center !important;
  }
  th[class*="antd-table-thead"] {
    padding: 0px;
    border: none;
    border-color: #fff !important;
    text-align: center;
  }
  tr[class*="antd-table-body"] {
    padding: 0px;
    border-color: #fff !important;
    text-align: center;
  }
  th[class*="antd-table-body"] {
    padding: 0px;
    border-color: #fff !important;
    text-align: center;
  }
  div[class*="ant-table-header"] {
    border-radius: 0px !important;
    border-color: #fff !important;
    padding: 0px !important;
    border: none !important;
  }
  tr[class*="ant-table-header"] {
    border-radius: 0px !important;
    border-color: #fff !important;
    padding: 0px !important;
    border: none !important;
  }
  thead[class*="ant-table-thead"] > tr > th {
    border: none;
    ::before{
      background-color: #fff;
    }
  }
  thead[class*="ant-table-thead"] > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    border: none;
    
      background-color: #fff;
    
  }
  tbody[class*="ant-table-tbody"] > tr > td {
    border-color: gray;
  }
}
.customTable .ant-table-thead > tr > th {
  border: none;
  border-color: #fff !important;
  border-radius: 0px !important;
}
.data{
  color: #000; 
  font-size: 13px;
  font-weight: 700;
  text-align:left;
  padding-left:20px;
}
.input{
  color: #000000;
   font-size: 14px;
   font-weight: 700;
   text-align:left;
}
.margin{
  margin-right:10px;
}
.property{
  text-align: left;
  font-weight: 300;
  color: #000;
  font-size:10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  //padding-left:20px;
}
.module{
  text-align: left;
  font-weight: 500;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.output{
  margin-right:10px;
  font-weight:700;
}
.font{
font-size: 12px; 
}
.mobile{
font-weight: 700;
color: #000; 
}
.stage{
  color: #000; 
  font-size: 13px;
  font-weight: 700;
  text-align:left;
  //padding-left:15px;
}
.dataindex{
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  text-align:left;
  //padding-left:15px;
}
.point{
  font-size: 10px;
}
.stagemob{
  display:inline-block;
  width:25px;
}
.mob{
  min-height: 500px;
  background-color: white;
  margin-right:20px;
}
.stats{

  margin-top: -20px;
}